.typoFontColor {
  color: #ffffff;
  padding-left: 1rem !important;
}

.formColor {
  color: #b0b0b0 !important;
  background-color: #000000 !important;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #636466 !important;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 2px rgba((123, 138, 139), 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formColor:focus {
  outline: none !important;
}

/* Change the border color to transparent when focused */
.formColor.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.slider {
  color: #d80607 !important;
}

.slider:hover {
  color: #f02514 !important;
}

.filter-section {
  position: sticky;
  z-index: 1000;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  max-width: 89%;
  padding-right: 0 !important;
}

/* Style for tablet and desktop */
@media (min-width: 768px) {
  .filter-section {
    display: flex;
    flex-direction: column;
  }
}
.no-right-padding {
  padding-right: 0 !important;
}
.no-left-padding {
  padding-left: 0 !important;
}
.css-1ras7pe-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1ras7pe-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #d80607 !important;
}

.css-1d4mxgk-MuiFormLabel-root.Mui-focused {
  color: #d80607 !important;
}

.css-u5sche.Mui-checked,
.css-u5sche.MuiCheckbox-indeterminate {
  color: #d80607 !important;
}
.css-w17y4q.Mui-focused {
  color: #d80607 !important;
}
.custom-btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #f4f4f4;
  --bs-btn-border-color: #f4f4f4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cfcfcf;
  --bs-btn-hover-border-color: #c3c3c3;
  --bs-btn-focus-shadow-rgb: 207, 207, 207;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4f4f4;
  --bs-btn-active-border-color: #f4f4f4;
  --bs-btn-disabled-color: #f4f4f4;
  --bs-btn-disabled-bg: #d80607;
  --bs-btn-disabled-border-color: #000000;
}

/* filter button mobile */
.filter-collapse {
  display: none; /* Hidden by default */
}

@media (min-width: 769px) {
  .filter-collapse {
    display: block; /* Visible on desktop */
  }
}

.custom-form-control-label {
  border-bottom: 1px solid #636466;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  transition: background-color 0.2s ease;
}

.custom-form-control-label:last-child {
  border-bottom: none;
}

.custom-form-control-label:hover {
  background-color: rgba(99, 100, 102, 0.1);
}

.filter-group {
  border: 1px solid rgba(99, 100, 102, 0.3);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.filter-toggle-button {
  display: inline-block; /* Default display property for mobile */
}

@media (min-width: 769px) {
  .filter-toggle-button {
    display: none; /* Hide the button on desktop */
  }
}
