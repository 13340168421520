main {
  min-height: 80vh;
  min-height: 80svh;
}

/*
RATINGS
*/
.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

/*
Products
*/
.product-title {
  height: auto; /* Remove fixed height to allow multiple lines */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Use normal instead of nowrap to allow text to wrap */
}

table td,
table th {
  text-align: center;
}

/*
REVIEWS
*/
.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #000000;
  padding: 10px;
  border: 1px solid #636466;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/*
LANDING PAGE
*/

.full-width-image {
  width: 100%;
  height: auto;
}

.landing-page-text {
  font-size: 1.125rem;
  color: #f8f9fa;
  line-height: 1.5rem;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.icon-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.image-container {
  position: relative;
}

.image-overlay {
  position: absolute;
  bottom: 17%;
  left: 2.5%; /* check if 2.5% or 3% w/ indi*/
  padding: 1rem;
}

/* For screens wider than 1600px */
@media (min-width: 1601px) {
  .image-overlay {
    bottom: 16%;
    left: 2.4%;
  }
}

/* For screens between 1400px and 1600px */
@media (min-width: 1401px) and (max-width: 1600px) {
  .image-overlay {
    bottom: 10%;
    left: 2.45%;
  }
}

/* For screens between 1200px and 1400px */
@media (min-width: 1201px) and (max-width: 1400px) {
  .image-overlay {
    bottom: 10%;
    left: 2.55%;
  }
}

/* For screens between 1000px and 1200px */
@media (min-width: 1001px) and (max-width: 1200px) {
  .image-overlay {
    bottom: 10%;
    left: 2.6%;
  }
}

/* For screens smaller than 1000px but still desktop */
@media (min-width: 769px) and (max-width: 1000px) {
  .image-overlay {
    bottom: 10%;
    left: 2.7%;
  }
}

/* OR */
.image-button {
  background-color: #fafafa;
  color: #000;
  border-color: transparent;
}

.hashtag-list {
  list-style-type: none;
  padding-left: 1.25rem;
}

.hashtag-list li::before {
  margin-left: -1.25rem;
  margin-right: 0.625rem;
}

/* IMAGES */
.desktop-image {
  width: 640px;
  height: 510px;
  object-fit: cover; /* This will ensure that the aspect ratio is maintained */
}

.carousel-item-bg {
  background-color: #000000;
}

/* SOCIALS */

.social-media h4 {
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.social-media .mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.social-media .text-center {
  margin-bottom: 1rem;
}

/* PROUD PARTNERS */
.proud-partners-container,
.proud-partners-container * {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

body,
.main-content,
.main-content * {
  font-family: "Segoe UI", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", Arial, sans-serif !important;
}

/* Ensure header keeps its font */
header,
header * {
  font-family: var(--bs-font-sans-serif) !important;
}

/* Sale Banner responsive font sizes */
.sale-banner {
  font-size: 0.925rem !important; /* Base size for mobile */
}

/* Larger font for tablets and up */
@media (min-width: 768px) {
  .sale-banner {
    font-size: 1.25rem !important;
  }
}

/* Even larger font for desktop */
@media (min-width: 1024px) {
  .sale-banner {
    font-size: 1.25rem !important;
  }
}
