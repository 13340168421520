/* CSS for Back To Top Button */
:root {
  --bg-dark: rgba(26, 26, 27, 0.8);
  --white: hsl(0, 0%, 100%, 1);
  --red: #d80607;
  --transition-1: 0.3s ease-in-out;
  --radius-circle: 50%;
  --shadow-2: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-top-btn {
  position: fixed;
  bottom: 1.25rem; /* 20px / 16px */
  right: 1.25rem; /* 20px / 16px */
  width: 3.125rem; /* 50px / 16px */
  height: 3.125rem; /* 50px / 16px */
  background-color: var(--bg-dark);
  color: var(--white);
  display: grid;
  place-items: center;
  border-radius: var(--radius-circle);
  border: 0.1875rem solid var(--red); /* 3px / 16px */
  transition: var(--transition-1);
  visibility: hidden;
  opacity: 0;
  z-index: 4;
  cursor: pointer;
}

.back-top-btn:is(:hover, :focus-visible) {
  box-shadow: 0 0 10px var(--red), 0 0 20px var(--red);
}

.back-top-btn.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(-10px);
}

/* Adjust position on mobile */
@media (max-width: 768px) {
  .back-top-btn {
    bottom: 0.625rem; /* 10px / 16px */
    right: 0.625rem; /* 10px / 16px */
    width: 2.5rem; /* 40px / 16px */
    height: 2.5rem; /* 40px / 16px */
    border-width: 0.125rem; /* 2px / 16px */
  }
}
