p {
  color: #9d9fa2;
  font-size: 0.85rem;
  line-height: 1.125rem;
  margin-bottom: 0rem !important;
}

.custom-padding {
  padding-top: -0.5rem;
  padding-bottom: 0.5rem;
}

.footer-links-wrapper {
  display: flex;
  justify-content: center;
  gap: 0.5rem; /* Adjust this value to control the spacing between links */
}

.footer-links {
  font-size: 0.8rem;
  text-decoration: none;
  color: #d0d1d2;
}

.footer-links:hover {
  text-decoration: underline;
}

.footer-row {
  margin: 10px 0;
}
