.logo {
  width: 6.5rem; /* Adjust the width to your desired size */
  height: 6.5rem; /* Adjust the height to your desired size */
  margin-right: 10px; /* Adjust the margin to create spacing between the logo and text */
  object-fit: cover; /* Ensure the logo maintains its aspect ratio and fits within the specified dimensions */
}

/* NAV BAR */
/* For desktop view */
@media (min-width: 1025px) {
  .navbar-nav.desktop-nav-tabs {
    order: -1; /* This will position the tabs at the beginning */
  }
}

/* For mobile and tablet view */
@media (max-width: 1024px) {
  .navbar-nav.desktop-nav-tabs {
    order: 2; /* This will position the tabs after the search box */
  }
  form.d-flex {
    order: 1; /* This will position the search box at the beginning */
  }
}
