/* StoreFrontScreen.css */
.store-front-container .store-card {
  cursor: pointer;
  height: auto; /* Height adjusts to content */
  margin: auto; /* Centers the card */
  overflow: hidden; /* Ensures no part of the image overflows the card */
  min-height: 25rem;
}

.store-front-container .store-card .card-img-top {
  width: 100%; /* Makes the image span the entire width of the card */
  height: 70%;
  object-fit: contain; /* Ensures the image is fully visible */
  transition: transform 0.3s ease-in-out; /* Smooth transition for zoom effect */
}

.store-front-container .store-card:hover .card-img-top {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.store-front-container .store-card .card-body {
  text-align: center; /* Center align the text */
  margin-bottom: -10rem;
}

.store-card-text {
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.4; /* Adjust line height for better readability */
}

@media (min-width: 768px) {
  .store-front-container .store-card {
    min-height: 600px; /* Apply min-height for larger screens */
  }
}

@media (max-width: 767px) {
  .store-front-container .store-card {
    margin-bottom: 1rem; /* Add some space below each card */
  }

  .store-front-container .store-card .card-body {
    margin-bottom: 0; /* Reset the negative margin for mobile */
  }
}

.discount-banner {
  position: absolute;
  top: 20px;
  right: -35px;
  background: #d80607;
  color: white;
  padding: 5px 40px;
  transform: rotate(45deg);
  font-weight: bold;
  font-size: 0.9rem;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.store-card {
  position: relative;
  overflow: hidden;
}
